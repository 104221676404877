<template>
  <div v-if="data">
    <component
      v-for="(item, index) in data.decoratedStory.raw"
      :key="index"
      :blok="item"
      :is="item.component"
      :currency="data.currency"
      :global="data.decoratedStory.global"
    />
  </div>
</template>

<script setup>
definePageMeta({
  layout: 'cms',
  colorMode: 'light',
})

const locales = ['/es'] // 'pt', 'fr', 'be', 'it', 'de', 'at']
const route = useRoute()
if (!locales.includes(route.path)) {
  throw createError({
    statusCode: 404,
    statusMessage: 'Page Not Found',
  })
}

const { data } = await useFetch('/api/public/cms/published/home')

useHead({
  title: 'Perfumerias San Remo - Bienvenida/o a nuestra web',
  script: [
    {
      type: 'application/ld+json',
      // TODO: Editar la información
      innerHTML: `{
    "@context": "http://schema.org",
    "@type": "Organization",
    "name": "Perfumerias San Remo",
    "legalName": "Perfumerias San Remo S.L.",
    "taxID": "B-86658606",
    "alternateName": ["Perfumerias San Remo", "perfumeriassanremo.es"],
    "url": "https://perfumeriassanremo.es/es",
    "email": "ayuda(at)nutritienda.com",
    "logo": "https://nutritienda-cdn.sirv.com/logo-nthb.png",
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Griñon, Spain",
      "postalCode": "28971",
      "streetAddress": "C/ Holanda, 5"
    },
    "contactPoint": [
      {
        "@type": "ContactPoint",
        "telephone": "+34 902 87 77 25",
        "contactType": "customer service",
        "areaServed": "ES"
      }
    ],
    "department": [
      {
        "@type": "Organization",
        "name": "Parafarmacia: un completo catálogo de productos para bebés y mamás, belleza y cosmética, cuidado y protección de la piel, salud sexual, higiene, nutrición, dietética ¡y muchos más productos para toda la familia!",
        "url": "https://perfumeriassanremo.es/es/parafarmacia"
      },
      {
        "@type": "Organization",
        "name": "Herbolario: productos de fitoterapia y remedios naturales para el cuidado de la salud y el bienestar, complementos para el control de peso, alimentación sin gluten, eco-bio… ¡el cuidado de tu salud a un solo clic!",
        "url": "https://perfumeriassanremo.es/es/herbolario"
      },
      {
        "@type": "Organization",
        "name": "Perfumería: buscas el perfume perfecto para ti o para regalar, en nuestra tienda online encontrarás perfumes para hombre, mujer, niños, unisex, estuches... ¡más de 4.000 perfumes te esperan!",
        "url": "https://perfumeriassanremo.es/es/perfumeria"
      },
      {
        "@type": "Organization",
        "name": "Maquillaje: tu sección de belleza con miles de productos de maquillaje para que puedas crear cualquier look. Lápiz de ojos, brochas de maquillaje, esponjas, coloretes, bases, bb cream... ¡maquíllate al mejor precio!",
        "url": "https://perfumeriassanremo.es/es/maquillaje"
      },
      {
        "@type": "Organization",
        "name": "Cosmética: mima tu piel con las marcas más exclusivas de alta cosmética para el cuidado y tratamiento facial y corporal: cremas hidratantes, exfoliantes y peeling, agua micelar, cremas reductoras y anticelulíticas, tónicos y sérums...",
        "url": "https://perfumeriassanremo.es/es/cosmetica"
      },
      {
        "@type": "Organization",
        "name": "Cabello y productos de peluquería: ponemos a tu alcance miles de productos para el cuidado del cabello y utensilios de peluquería. Encuentra tu estilo y presume de cabello con la ayuda de las mejores marcas de champú, acondicionadores, mascarillas, keratina, tintes, caída de cabello, etc..",
        "url": "https://perfumeriassanremo.es/es/cabello-productos-peluqueria"
      }
    ]
  }
  `,
    },
  ],
})

const { onLoaded, sendPageVisit, subscribeToPush } = useConnectif()
onLoaded(() => {
  sendPageVisit()
  subscribeToPush()
})

if (import.meta.server) {
  // Get underlying request event
  const event = useRequestEvent()
  // TODO: Anadir etiquetas de productos, product:123, product:234, etc...
  event.context.cache = {
    tags: ['home'],
  }
}
</script>
